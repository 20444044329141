import React from "react"
import vegas from "../../images/marquee.jpg"
import { top, vegasStyle, copy, links, linkTitle } from "./styles.module.less"

const News = () => {
  const items = [
    {
      title: `Beating the Cheats`,
      link: "https://edition.pagesuite.com/html5/reader/production/default.aspx?pubname=&edid=00b07234-f863-4784-88f3-db6b53c9b04b&pnum=32",
      source: `interGaming July 2024`,
    },
    {
      title: `Casino Cheating Expert Reviews Card Counting and Casino Scams From Movies | Vanity Fair`,
      link: "https://www.youtube.com/watch?v=ki4Fbctwnjs",
      source: `YouTube, September 30, 2021`,
    },
    {
      title: `Looking to Catch a Card Cheater? Better Call Sal, the
  International Casino Expert`,
      link:
        "https://www.theguardian.com/society/2015/nov/25/sal-piacente-cards-casinos-cheating",
      source: `(with video) The Guardian, November 25, 2015`,
    },
    {
      title: `Basic Casino Cheating Scams Hardest to Catch`,
      link:
        "http://www.reviewjournal.com/business/casinos-gaming/basic-casino-cheating-scams-hardest-catch-gaming-experts-say",
      source: `(with video) Las Vegas Review-Journal September 26, 2016`,
    },
    {
      title: `Cheater's Justice`,
      link:
        "http://www.maxim.com/entertainment/cheaters-justice-brutal-payback-duping-dealers-casinos-2015-10",
      source: `Maxim Magazine October 15, 2015`,
    },
    {
      title: `Inside the Art of Cheating`,
      link:
        "http://www.today.com/money/inside-art-cheating-tips-gambling-experts-sal-piacente-rick-blaine-t41486",
      source: `Today.com March 30, 2015`,
    },
    {
      title: `Players Advantage: 'Most Wanted' Casino Cheater Used Old Tricks`,
      link: "http://triblive.com/aande/gambling/6531042-74/says-casino-percent",
      source: `Tribune-Review August 3, 2014`,
    },
    {
      title: `Florence Man Gives Game Advice on Travel Channel Show`,
      link:
        "http://www.cincinnati.com/story/news/2014/04/28/sal-piacente-travel-channel-game-america/8413859/",
      source: "Cincinnati Enquirer April 28, 2014",
    },
    {
      title: `People Continue to Find Ways to Cheat Casinos`,
      link:
        "http://triblive.com/x/pittsburghtrib/ae/gambling/s_765591.html#axzz31cnaUSyC",
      source: "Pittsburgh Tribune November 4, 2011",
    },
    {
      title: `Asian Casinos Fight World's Best Cheats`,
      link:
        "http://business.inquirer.net/61243/asian-casinos-fight-worlds-best-cheats%E2%80%94experts",
      source: "Agence France-Presse May 24, 2012",
    },
    {
      title: `Most Interesting People 2009 (18th down)`,
      link: "http://archive.is/aDDAH",
      source: "Cincy Magazine April/May 2009",
    },
    {
      title: `Memory Expert Gives Tips to Learn More, Study Less`,
      link: "http://www.enquirer.com/editions/2003/10/09/loc_kymemory09.html",
      source: "Cincinnati Enquirer October 9, 2003",
    },
  ]

  return (
    <div className={top}>
      <img
        src={vegas}
        alt="Las Vegas Sign"
        style={{ display: "inline" }}
        className={vegasStyle}
      />
      <div className={copy}>
        <p>
          The following links are being provided as a convenience and are for
          informational purposes only. UniverSal Game Protection, Inc. bears no
          responsibility for the accuracy, legality, or content of the external
          site or for that of subsequent links. Contact the external site for
          answers to questions regarding its content.
        </p>
        <div className={links}>
          {items.map(({ title, link, source }) => {
            return <LinkComponent title={title} link={link} source={source} />
          })}
        </div>
      </div>
    </div>
  )
}

export default News

const LinkComponent = ({ title, link, source }) => {
  return (
    <div>
      <h5 className={linkTitle}>
        <b>
          <i>
            {" "}
            <a href={link}>{title}</a>
          </i>
        </b>
      </h5>
      <p>{source}</p>
    </div>
  )
}
