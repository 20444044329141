import React from "react"
import News from "../components/news"
import Layout from "../components/layout"
import { Seo } from "../components/seo"

const NewsPage = () => (
  <Layout>
    <h1>News</h1>
    <News />
  </Layout>
)

export default NewsPage

export const Head = () => <Seo title="News | Universal Game Protection" />
